import StoreService from "@/api/services/store";
import AdminService from "@/api/services/admin";
import { isPresent } from "@/utils/validation";

const initialState = {
  availableFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    stores: [],
  },
  selectedFilters: {
    billing: [],
    chargedBy: [],
    status: [],
    stores: [],
  },
  planSales: [],
  totalNumberOfPlanSales: null,
  keywords: "",
  startDate: null,
  endDate: null,
  isAdmin: null,
  selectedStoreId: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getDateRange: (state) => {
      return { startDate: state.startDate, endDate: state.endDate };
    },
  },

  mutations: {
    setPlanSales(state, { planSales, totalNumberOfPlanSales }) {
      state.planSales = planSales;
      state.totalNumberOfPlanSales = totalNumberOfPlanSales;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setAvailableFilters(state, { billing, chargedBy, status, stores }) {
      state.availableFilters.billing = billing;
      state.availableFilters.chargedBy = chargedBy;
      state.availableFilters.status = status;
      state.availableFilters.stores = stores;
    },

    setSelectedFilters(state, { billing, chargedBy, status, stores }) {
      state.selectedFilters.billing = billing;
      state.selectedFilters.chargedBy = chargedBy;
      state.selectedFilters.status = status;
      state.selectedFilters.stores = stores;
    },

    setDates(state, { startDate, endDate }) {
      state.startDate = startDate;
      state.endDate = endDate;
    },

    setIsAdmin(state, { isAdmin }) {
      state.isAdmin = isAdmin;
    },

    setSelectedStoreId(state, { selectedStoreId }) {
      state.selectedStoreId = selectedStoreId;
    },
  },

  actions: {
    async getPlanSales({ state, commit }, { page, isAdmin, storeId }) {
      if (isPresent(isAdmin)) {
        commit("setIsAdmin", { isAdmin: isAdmin });
      }

      if (isPresent(storeId)) {
        commit("setSelectedStoreId", { selectedStoreId: storeId });
      }

      try {
        let response = null;
        if (state.isAdmin) {
          response = await AdminService.getPlanSales({
            billing: state.selectedFilters.billing,
            charged_by: state.selectedFilters.chargedBy,
            status: state.selectedFilters.status,
            stores: state.selectedFilters.stores,
            keywords: state.keywords,
            page: page,
            start_date: state.startDate,
            end_date: state.endDate,
          });
        } else {
          response = await StoreService.getPlanSales(
            {
              billing: state.selectedFilters.billing,
              charged_by: state.selectedFilters.chargedBy,
              status: state.selectedFilters.status,
              keywords: state.keywords,
              page: page,
              start_date: state.startDate,
              end_date: state.endDate,
            },
            state.selectedStoreId
          );
        }

        commit("setPlanSales", {
          planSales: response.data.planSales,
          totalNumberOfPlanSales: response.data.totalNumberOfPlanSales,
        });

        commit("setAvailableFilters", {
          billing: response.data.filters.billing,
          chargedBy: response.data.filters.chargedBy,
          status: response.data.filters.status,
          stores: response.data.filters.stores,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    updateSearch({ commit, dispatch }, { searchString, page }) {
      commit("setKeywords", searchString);
      dispatch("getPlanSales", {
        page: page,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page }) {
      commit("setFilter", { filterName, filterOptions });
      dispatch("getPlanSales", {
        page: page,
      });
    },

    updateDateFilter({ commit, dispatch }, { startDate, endDate, page }) {
      commit("setDates", { startDate, endDate });
      dispatch("getPlanSales", {
        page: page,
      });
    },

    resetFilters({ commit }) {
      commit("setKeywords", "");
      commit("setSelectedFilters", {
        billing: [],
        chargedBy: [],
        status: [],
        stores: [],
      });
      commit("setDates", {
        startDate: null,
        endDate: null,
      });
      commit("setSelectedStoreId", {
        selectedStoreId: null,
      });
    },

    async getPlanSaleCSVExport({ state }) {
      return await AdminService.getPlanSaleCSVExport({
        billing: state.selectedFilters.billing,
        charged_by: state.selectedFilters.chargedBy,
        status: state.selectedFilters.status,
        stores: state.selectedFilters.stores,
        keywords: state.keywords,
        start_date: state.startDate,
        end_date: state.endDate,
      });
    },
  },
};

<template>
  <div class="mt-10">
    <h2 class="text-3xl pb-4 pl-2 font-extrabold tracking-tight text-gray-900">
      {{ $t("Plan Sale Billing details") }}
    </h2>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          class="inline-block min-w-full py-2 pb-12 align-middle md:px-6 lg:px-8"
        >
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Amount") }}
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Fee") }}
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Time range") }}
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Payment Status") }}
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Invoice") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="planSaleBilling in planSaleBillings"
                  :key="planSaleBilling.id"
                  :class="tableLineBackgroundColor(planSaleBilling)"
                >
                  <td
                    :class="[
                      'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900',
                      isCollected(planSaleBilling)
                        ? 'text-green-500'
                        : 'text-red-500',
                    ]"
                  >
                    {{ isCollected(planSaleBilling) ? "+ " : "- " }}
                    {{ formatFrenchPrice(planSaleBilling.finalPrice) }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <span
                      class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium bg-purple-100 text-purple-700"
                    >
                      {{ formatFrenchPrice(planSaleBilling.commission.amount) }}
                    </span>
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    {{ planSaleBilling.startDate }} au
                    {{ planSaleBilling.endDate }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <FieldSpan
                      :styleToAdd="paymentStatusColor(planSaleBilling)"
                      :value="paymentStatusTranslation(planSaleBilling)"
                    />
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <div v-if="planSaleBilling.uncollectible" class="flex">
                      <div class="bg-gray-100 rounded-xl flex px-2 py-1.5">
                        <BanIcon
                          class="h-6 w-6 text-gray-800"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div
                      v-else-if="planSaleBilling.discountApplied"
                      class="flex"
                    >
                      <GiftIcon
                        class="h-6 w-6 text-primary-dark cursor-pointer"
                        aria-hidden="true"
                      />
                      <span
                        class="pl-2 text-base font-semibold text-primary-dark"
                      >
                        {{ $t("Offered") }}
                      </span>
                    </div>
                    <div
                      v-else-if="planSaleBilling.paymentStatus === 'succeeded'"
                      class="flex"
                    >
                      <CloudDownloadIcon
                        v-if="planSaleBilling.invoiceUrl"
                        class="h-6 w-6 text-primary-dark cursor-pointer"
                        aria-hidden="true"
                      />
                      <a
                        v-if="planSaleBilling.invoiceUrl"
                        @click.prevent="handleDownload(planSaleBilling.id)"
                        class="pl-2 text-base font-semibold text-primary-dark cursor-pointer"
                        target="_blank"
                      >
                        {{ $t("Download") }}
                      </a>
                    </div>
                    <div
                      v-else
                      class="px-3 py-0.5 rounded-full text-sm font-medium bg-secondary"
                    >
                      <p>{{ $t("Available soon") }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { CloudDownloadIcon, GiftIcon, BanIcon } from "@heroicons/vue/outline";
import AdminService from "@/api/services/admin";
import { formatFrenchPrice } from "@/utils/price_formatter";

export default {
  props: {
    planSaleBillings: {
      type: Array,
      required: true,
    },
    planSaleId: {
      type: Number,
      required: true,
    },
  },

  components: {
    FieldSpan,
    CloudDownloadIcon,
    GiftIcon,
    BanIcon,
  },

  methods: {
    formatFrenchPrice,

    paymentStatusColor(planSaleBilling) {
      switch (this.paymentStatus(planSaleBilling)) {
        case "draft":
        case "waiting":
          return "bg-yellow-100 text-yellow-800";
        case "succeeded":
          return "bg-green-100 text-green-800";
        case "failed":
        case "disputed":
          return "bg-red-100 text-red-800";
        case "uncollectible":
          return "bg-gray-100 text-gray-800";
        default:
          return "";
      }
    },

    paymentStatusTranslation(planSaleBilling) {
      const paymentStatus = this.paymentStatus(planSaleBilling);

      return this.$t(`plan_sale_billing.payment_status.${paymentStatus}`);
    },

    tableLineBackgroundColor(planSaleBilling) {
      switch (this.paymentStatus(planSaleBilling)) {
        case "failed":
        case "disputed":
        case "uncollectible":
          return "bg-gray-100";
        default:
          return "";
      }
    },

    handleDownload(planSaleBillingId) {
      AdminService.getPlanSaleBillingInvoice(this.planSaleId, planSaleBillingId)
        .then((response) => {
          const url = response.data.invoice_url;
          if (url) window.open(url, "_blank");
        })
        .catch((error) => {
          console.error("Error fetching the invoice URL:", error);
        });
    },

    isCollected(planSaleBilling) {
      return planSaleBilling.billingType === "collected";
    },

    paymentStatus(planSaleBilling) {
      return planSaleBilling.uncollectible
        ? "uncollectible"
        : planSaleBilling.paymentStatus;
    },
  },
};
</script>

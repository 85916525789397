<template>
  <div>
    <transition
      enter-active-class="transition ease-out duration-1000"
      enter-from-class="transform opacity-0 scale-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-700"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-100"
    >
      <div class="text-button-font-primary bg-primary h-24 w-full">
        <div class="mx-auto px-4 sm:px-6 md:px-8 h-full">
          <div class="flex items-center h-full">
            <div class="flex-shrink-0 flex items-center px-4">
              <img
                class="h-12 w-auto"
                src="@/assets/logo_sidebar.png"
                alt="Estaly"
              />
            </div>
            <transition
              enter-active-class="transition-opacity duration-1000"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity duration-700"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div class="ml-6 spring-spinner" v-show="processing">
                <div class="spring-spinner-part top">
                  <div class="spring-spinner-rotator"></div>
                </div>
                <div class="spring-spinner-part bottom">
                  <div class="spring-spinner-rotator"></div>
                </div>
              </div>
            </transition>
            <div class="flex-grow">
              <div class="px-12">
                <transition
                  enter-active-class="transition-opacity duration-1000"
                  enter-from-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition-opacity duration-700"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <p
                    class="text-gray-200"
                    v-bind:key="currentOnboardingStep.text"
                  >
                    {{ currentOnboardingStep.text }}
                  </p>
                </transition>
              </div>
            </div>
            <transition
              enter-active-class="transition-opacity duration-1000"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity duration-700"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div v-show="revenueEstimate">
                <div class="px-6">
                  <p class="text-gray-200 uppercase text-xs">
                    Estimated annual warranty profit
                  </p>
                  <p class="text-right text-gray-200 font-bold">
                    € {{ revenueEstimate }}
                  </p>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
    <div class="py-4 pb-24">
      <div class="max-w-7xl mx-auto px-4">
        <div>
          <div class="pb-4 pl-2">
            <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">
              Welcome!
            </h1>
            <p class="mt-2 text-sm text-gray-500" id="store-type-description">
              To access your dashboard and set up your warranty program, please
              fill in some information about you and your store.
            </p>
          </div>
          <VeeForm
            v-slot="{ handleSubmit }"
            v-bind:validation-schema="schema"
            as="div"
            class="w-full"
            ref="form"
          >
            <form
              v-on:submit.prevent="handleSubmit($event, handleSubmitOnboarding)"
            >
              <div class="space-y-6 mb-6">
                <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Store
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">
                        This is need in order to set up your program.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="store-type"
                            class="block text-sm font-medium text-gray-700"
                            >Store type</label
                          >
                          <Field
                            v-slot="{ value }"
                            name="storeType"
                            as="select"
                            id="store-type"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                            v-model="storeInfo.storeType"
                          >
                            <option
                              v-for="(storeType, id) in storeTypes"
                              v-bind:key="id"
                              v-bind:value="storeType.value"
                              v-bind:selected="
                                value && value.includes(storeType.value)
                              "
                            >
                              {{ storeType.name }}
                            </option>
                          </Field>
                          <p
                            class="mt-2 text-sm text-gray-500"
                            id="store-type-description"
                          >
                            This will be used to match your products with our
                            available warranty plans.
                          </p>
                          <ErrorMessage
                            name="storeType"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-3">
                          <label
                            for="annual-sales-estimate"
                            class="block text-sm font-medium text-gray-700"
                            >Annual sales estimate</label
                          >
                          <div class="mt-1 relative rounded-md shadow-sm">
                            <div
                              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                            >
                              <span class="text-gray-500 sm:text-sm"> € </span>
                            </div>
                            <Field
                              type="text"
                              name="annualSalesEstimate"
                              id="annual-sales-estimate"
                              class="focus:ring-primary focus:border-primary block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                              placeholder="5,000,000"
                              v-model="storeInfo.annualSalesEstimate"
                            />
                            <div
                              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                            >
                              <span
                                class="text-gray-500 sm:text-sm"
                                id="price-currency"
                              >
                                EUR
                              </span>
                            </div>
                          </div>
                          <p
                            class="mt-2 text-sm text-gray-500"
                            id="store-type-description"
                          >
                            This will be used to estimate the revenue you will
                            generate with Estaly.
                          </p>
                          <ErrorMessage
                            name="annualSalesEstimate"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="space-y-6 mb-6">
                <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Personal Information
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">
                        This is needed for contact and legal purposes.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                            >First name</label
                          >
                          <Field
                            type="text"
                            name="firstName"
                            id="first-name"
                            autocomplete="given-name"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="userInfo.firstName"
                          />
                          <ErrorMessage
                            name="firstName"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="last-name"
                            class="block text-sm font-medium text-gray-700"
                            >Last name</label
                          >
                          <Field
                            type="text"
                            name="lastName"
                            id="last-name"
                            autocomplete="family-name"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="userInfo.lastName"
                          />
                          <ErrorMessage
                            name="lastName"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6">
                          <label
                            for="address-1"
                            class="block text-sm font-medium text-gray-700"
                            >Address line 1</label
                          >
                          <Field
                            type="text"
                            name="address1"
                            id="address-1"
                            autocomplete="address-line1"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="userInfo.address1"
                          />
                          <ErrorMessage
                            name="address1"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6">
                          <label
                            for="address-2"
                            class="block text-sm font-medium text-gray-700"
                            >Address line 2</label
                          >
                          <Field
                            type="text"
                            name="address2"
                            id="address-2"
                            autocomplete="address-line2"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="userInfo.address2"
                          />
                          <ErrorMessage
                            name="address2"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            for="country"
                            class="block text-sm font-medium text-gray-700"
                            >Country</label
                          >
                          <Field
                            v-slot="{ value }"
                            name="country"
                            as="select"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                            v-model="userInfo.country"
                          >
                            <option
                              v-for="(country, id) in countries"
                              v-bind:key="id"
                              v-bind:value="country.code"
                              v-bind:selected="
                                value && value.includes(country.code)
                              "
                            >
                              {{ country.name }}
                            </option>
                          </Field>
                          <ErrorMessage
                            name="country"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            for="province"
                            class="block text-sm font-medium text-gray-700"
                            >State / Province</label
                          >
                          <Field
                            type="text"
                            name="province"
                            id="province"
                            autocomplete="address-level1"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="userInfo.province"
                          />
                          <ErrorMessage
                            name="region"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                          <label
                            for="city"
                            class="block text-sm font-medium text-gray-700"
                            >City</label
                          >
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            autocomplete="address-level2"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="userInfo.city"
                          />
                          <ErrorMessage
                            name="city"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            for="zip-code"
                            class="block text-sm font-medium text-gray-700"
                            >ZIP / Postal code</label
                          >
                          <Field
                            type="text"
                            name="zipCode"
                            id="zip-code"
                            autocomplete="postal-code"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="userInfo.zipCode"
                          />
                          <ErrorMessage
                            name="zipCode"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="space-y-6">
                <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Business Information
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">
                        This is needed for contact and legal purposes.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="business-name"
                            class="block text-sm font-medium text-gray-700"
                            >Business name</label
                          >
                          <Field
                            type="text"
                            name="businessName"
                            id="business-name"
                            autocomplete="given-name"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="storeInfo.businessName"
                          />
                          <ErrorMessage
                            name="businessName"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            for="business-country"
                            class="block text-sm font-medium text-gray-700"
                            >Country</label
                          >
                          <Field
                            id="business-country"
                            v-slot="{ value }"
                            name="businessCountry"
                            as="select"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                            v-model="storeInfo.businessCountry"
                          >
                            <option
                              v-for="(country, id) in countries"
                              v-bind:key="id"
                              v-bind:value="country.code"
                              v-bind:selected="
                                value && value.includes(country.code)
                              "
                            >
                              {{ country.name }}
                            </option>
                          </Field>
                          <ErrorMessage
                            name="businessCountry"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                          <label
                            for="business-city"
                            class="block text-sm font-medium text-gray-700"
                            >City</label
                          >
                          <Field
                            type="text"
                            name="businessCity"
                            id="business-city"
                            autocomplete="address-level2"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="storeInfo.businessCity"
                          />
                          <ErrorMessage
                            name="businessCity"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label
                            for="business-zip-code"
                            class="block text-sm font-medium text-gray-700"
                            >ZIP / Postal code</label
                          >
                          <Field
                            type="text"
                            name="businessZipCode"
                            id="business-zip-code"
                            autocomplete="postal-code"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="storeInfo.businessZipCode"
                          />
                          <ErrorMessage
                            name="businessZipCode"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-end mt-6">
                <button
                  type="submit"
                  class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-button-font-primary bg-primary disabled:cursor-wait disabled:opacity-75"
                  v-bind:disabled="processing"
                >
                  <svg
                    class="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    v-show="processing"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>

                  {{ processing ? "Processing..." : "Save" }}
                </button>
              </div>
            </form>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import OnboardingService from "../../api/services/onboarding";
import { nextTick } from "vue";
import { countries, storeTypes } from "@/utils/data";

export default {
  components: { Field, VeeForm, ErrorMessage },

  data: () => {
    const schema = yup.object({
      storeType: yup.string().required("Please select a store type"),
      annualSalesEstimate: yup
        .string()
        .required("Please give an estimate of your annual sales"),
      firstName: yup.string().required("Please enter your first name"),
      lastName: yup.string().required("Please enter your last name"),
      country: yup.string().required("Please select your country"),
      address1: yup.string().required("Please enter your address"),
      city: yup.string().required("Please enter your city"),
      zipCode: yup.string().required("Please enter your zip code"),
      businessName: yup.string().required("Please enter your business name."),
      businessZipCode: yup
        .string()
        .required("Please enter your business zip code."),
      businessCity: yup.string().required("Please enter your business city."),
      businessCountry: yup
        .string()
        .required("Please enter your business country."),
    });

    return {
      userInfo: {},
      storeInfo: {
        storeType: "electronics",
      },
      countries,
      storeTypes,
      currentOnboardingStep: {},
      revenueEstimate: null,
      schema,
      processing: false,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    startOnboarding: async function () {
      const onboardingSteps = [
        {
          text: "Updating store and store utils...",
          callback: this.createOnboarding,
        },
        {
          text: "Syncing products with Shopify...",
        },
        // {
        //   text: "Estimating additional revenue based on annual sales estimate and store type...",
        //   callback: this.getRevenueEstimate,
        // },
      ];

      this.processing = true;

      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      for (const step of onboardingSteps) {
        this.currentOnboardingStep = step;
        if (step.callback) {
          try {
            await step.callback();
          } catch (error) {
            return false;
          }
        }
        await delay(2000);
        this.currentOnboardingStep = {};
        await delay(700);
      }

      this.processing = false;
      await delay(1000);
      return true;
    },

    getRevenueEstimate: async function () {
      try {
        const response = await OnboardingService.createRevenueEstimate(
          this.$store.state.store.selectedStoreId,
          this.storeInfo.annualSalesEstimate
        );
        this.revenueEstimate = response.data.revenue_estimate;
      } catch (error) {
        console.log(error);
      }
    },

    createOnboarding: async function () {
      try {
        const storeParams = {
          store_type: this.storeInfo.storeType,
          annual_sales_estimate: parseInt(
            this.storeInfo.annualSalesEstimate.replace(/,/g, ""),
            10
          ),
          business_name: this.storeInfo.businessName,
          business_zip_code: this.storeInfo.businessZipCode,
          business_city: this.storeInfo.businessCity,
          business_country: this.storeInfo.businessCountry,
        };

        const userParams = {
          first_name: this.userInfo.firstName,
          last_name: this.userInfo.lastName,
          country: this.userInfo.country,
          city: this.userInfo.city,
          zip_code: this.userInfo.zipCode,
          province: this.userInfo.province,
          address1: this.userInfo.address1,
          address2: this.userInfo.address2,
        };

        const storeId = this.$store.state.store.selectedStoreId;

        await OnboardingService.createOnboarding({
          storeId,
          storeParams,
          userParams,
        });
      } catch (error) {
        console.log(error.response);
        this.notify({
          category: "simple",
          type: "error",
        });
        return Promise.reject(error);
      }
    },

    handleSubmitOnboarding: async function () {
      const onboardingSuccessful = await this.startOnboarding();
      if (onboardingSuccessful) {
        this.notify({
          category: "simple",
          type: "success",
        });
      }
      this.$router.push("/platform/products");
    },
  },

  watch: {
    storeInfo: {
      handler: function (newValue) {
        this.storeInfo.storeType = newValue.storeType;
        nextTick(() => (this.storeInfo.storeType = newValue.storeType));

        if (!newValue.annualSalesEstimate) {
          return;
        }

        const result = newValue.annualSalesEstimate
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        nextTick(() => (this.storeInfo.annualSalesEstimate = result));
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.spring-spinner,
.spring-spinner * {
  box-sizing: border-box;
}

.spring-spinner {
  height: 45px;
  width: 45px;
}

.spring-spinner .spring-spinner-part {
  overflow: hidden;
  height: calc(45px / 2);
  width: 45px;
}

.spring-spinner .spring-spinner-part.bottom {
  transform: rotate(180deg) scale(-1, 1);
}

.spring-spinner .spring-spinner-rotator {
  width: 45px;
  height: 45px;
  border: calc(45px / 7) solid transparent;
  border-right-color: #eaeaea;
  border-top-color: #eaeaea;
  border-radius: 50%;
  box-sizing: border-box;
  animation: spring-spinner-animation 3s ease-in-out infinite;
  transform: rotate(-200deg);
}

@keyframes spring-spinner-animation {
  0% {
    border-width: calc(45px / 7);
  }
  25% {
    border-width: calc(45px / 23.33);
  }
  50% {
    transform: rotate(115deg);
    border-width: calc(45px / 7);
  }
  75% {
    border-width: calc(45px / 23.33);
  }
  100% {
    border-width: calc(45px / 7);
  }
}
</style>

<template>
  <div v-if="planSale != null">
    <div>
      <h1 class="text-3xl pb-4 font-extrabold tracking-tight text-gray-900">
        {{ $t("Plan Sale details") }}
      </h1>
      <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
        {{ $t("Protection details") }}
        <span class="font-bold">{{ planSale.id }}</span>
      </p>
    </div>

    <div class="mt-4">
      <CancellationDetails
        :cancellation="planSale.cancellation"
        v-if="planSale.cancellation"
      />
    </div>

    <div class="mt-4">
      <div class="mt-2 border-t border-gray-100 bg-white px-4 sm:px-6 py-5">
        <h2 class="col-span-2 text-xl font-bold mb-4">{{ $t("Customer") }}</h2>
        <dl class="grid grid-cols-2 gap-x-4 gap-y-6">
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("First Name") }} {{ $t("Last Name") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.firstName }}
              {{ planSale.customer.lastName }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Email address") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.email }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Address") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <p v-if="planSale.customer.address">
                {{ planSale.customer.address }}
              </p>
              <p class="font-bold" v-else>—</p>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Phone number") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.phone }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Birth date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.customer.birthDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Birth city") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ customerBirthAddress }}
            </dd>
          </div>
          <div v-if="planSale.orderReferenceId">
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Order ID") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.orderReferenceId }}
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="mt-6">
      <div class="mt-2 border-t border-gray-100 bg-white px-4 sm:px-6 py-5">
        <h2 class="col-span-2 text-xl font-bold mb-4">{{ $t("Insurance") }}</h2>
        <dl class="grid grid-cols-2 gap-x-4 gap-y-6">
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Warranty Price") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ formatFrenchPrice(planSale.price) }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Payment") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.monthlyBilling ? "Mensuel" : "Annuel" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Purchase date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.purchaseDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Contract start date") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.startDate }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Subscription form") }}
            </dt>
            <TextCopy
              class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 cursor-pointer underline-offset-4 hover:text-primary-dark"
              v-bind:textToCopy="planSale.membershipAgreement"
            >
              {{ $t("Copy link to clipboard") }}
            </TextCopy>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">
              {{ $t("Charged by") }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.chargedBy }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Shop") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ planSale.salesChannel }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-bold text-gray-500">{{ $t("Seller") }}</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <p v-if="sellerName">{{ sellerName }}</p>
              <p class="font-bold" v-else>—</p>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <ProductsDetails :plan-sale="planSale" />

    <PlanSaleBillingsDetails
      :plan-sale-id="planSaleId"
      :plan-sale-billings="planSale.planSaleBillings"
    />
  </div>
  <div v-else-if="!this.isLoading" class="text-center flex h-screen">
    <div class="m-auto">
      <p>{{ $t("Something went wrong.") }}</p>
      <GenericButton @onClick="goBackToPlanSalesList" class="mt-4">
        {{ $t("Back") }}
      </GenericButton>
    </div>
  </div>
</template>

<script>
import AdminService from "@/api/services/admin";
import TextCopy from "@/components/utils/TextCopy.vue";
import ProductsDetails from "@/views/admin/plansale/details/ProductsDetails.vue";
import PlanSaleBillingsDetails from "@/views/admin/plansale/details/PlanSaleBillingsDetails.vue";
import { formatFrenchPrice } from "@/utils/price_formatter";
import { mapActions, mapMutations, mapGetters } from "vuex";
import GenericButton from "@/components/utils/GenericButton.vue";
import CancellationDetails from "@/views/admin/plansale/details/CancellationDetails.vue";

export default {
  components: {
    GenericButton,
    PlanSaleBillingsDetails,
    ProductsDetails,
    TextCopy,
    CancellationDetails,
  },

  data() {
    return {
      planSale: null,
      planSaleId: this.$route.params.id,
    };
  },

  async mounted() {
    await this.getPlanSale();
  },

  computed: {
    ...mapGetters("store", ["isLoading"]),

    customerBirthAddress() {
      const birthInformations = [
        this.planSale.customer.birthCity,
        this.planSale.customer.birthCountry,
      ];

      return birthInformations.filter((el) => el).join(", ");
    },

    sellerName() {
      const sellerNameInformations = [
        this.planSale.seller.first_name,
        this.planSale.seller.last_name,
      ];

      return sellerNameInformations.filter((el) => el).join(" ");
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    formatFrenchPrice,

    async getPlanSale() {
      try {
        this.setIsLoading(true);
        const response = await AdminService.getPlanSale(this.planSaleId);
        this.planSale = response.data;
      } catch (error) {
        console.error(error);
        this.notify({
          category: "simple",
          type: "error",
          title: "Something went wrong.",
        });
      } finally {
        this.setIsLoading(false);
      }
    },

    goBackToPlanSalesList() {
      this.$router.push(`/admin/plan-sales`);
    },
  },
};
</script>

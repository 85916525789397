<template>
  <table class="min-w-full divide-y divide-gray-300 responsive-table">
    <thead>
      <tr class="grid grid-cols-7 bg-gray-50">
        <TableHeader
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          customClass="flex justify-center"
          columnKey="name"
          @sort="handleSort"
        >
          {{ $t("Name") }}
        </TableHeader>
        <TableHeader
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          customClass="flex justify-center"
          columnKey="total_sales"
          @sort="handleSort"
        >
          {{ $t("Portfolio contracts")}}
        </TableHeader>
        <TableHeader
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          customClass="flex justify-center"
          columnKey="new_sales"
          @sort="handleSort"
        >
          {{ $t("New contracts")}}
        </TableHeader>
        <TableHeader
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          customClass="flex justify-center"
          columnKey="total_gross_commissions"
          @sort="handleSort"
        >
          {{ $t("Gross commission")}}
        </TableHeader>
        <TableHeader
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          customClass="flex justify-center"
          columnKey="total_net_commissions"
          @sort="handleSort"
        >
          {{ $t("Net commission")}}
        </TableHeader>
        <TableHeader
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          customClass="flex justify-center"
          columnKey="gross_incentive"
        >
          {{ $t("Gross incentive")}}
        </TableHeader>
        <TableHeader
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          customClass="flex justify-center"
          columnKey="net_incentive"
        >
          {{ $t("Net incentive")}}
        </TableHeader>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200">
      <tr
        class="grid grid-cols-7 bg-gray-100"
      >
        <td
          class="break-words flex items-center col-span-1 py-2 pl-2 text-xs font-medium text-gray-900 sticky left-0"
        >
          {{ totalStats.name }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 pl-5 justify-center"
        >
          {{ totalStats.totalSales }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ totalStats.newSales }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ totalStats.totalGrossCommissions }} €
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ totalStats.totalNetCommissions }} €
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ totalStats.grossIncentive }} €
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ totalStats.netIncentive }} €
        </td>
      </tr>
      <tr
        class="grid grid-cols-7 bg-white"
        v-for="item in stats"
        :key="item.id"
      >
        <td
          class="break-words flex items-center col-span-1 py-2 pl-2 text-xs font-medium text-gray-900 sticky left-0 bg-white"
        >
          {{ item.name }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 pl-5 justify-center"
        >
          {{ item.totalSales }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ item.newSales }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ item.totalGrossCommissions }} €
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ item.totalNetCommissions }} €
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ item.grossIncentive }} €
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 justify-center"
        >
          {{ item.netIncentive }} €
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TableHeader from "@/components/analytics/retail/utils/TableHeader.vue";

export default {
  name: "StatsTable",

  components: {
    TableHeader,
  },

  props: {
    stats: {
      type: Array,
      required: true,
    },
    totalStats: {
      type: Object,
      required: true,
    }
  },

  data: () => {
    return {
      sortColumn: null,
      sortOrder: null,
    };
  },

  methods: {
    handleSort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }

      this.emitSortEvent();
    },

    emitSortEvent() {
      this.$emit("sortChanged", {
        column: this.sortColumn,
        order: this.sortOrder,
      });
    },
  },
};
</script>

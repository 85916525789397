<template>
  <div>
    <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
      {{ $t("Stores") }}
    </h1>
    <div v-if="currentUserHasPermission('admin')" class="flex flex-row-reverse">
      <GenericButton @click="createStore">
        {{ $t("Add a store") }}
      </GenericButton>
    </div>
    <SearchBar
      class="my-4"
      v-bind:current-page="this.currentPage"
      v-bind:placeholder="'Recherche de point de vente par nom/url'"
      v-bind:initial-keywords="this.keywords"
      v-bind:available-filters="[]"
      v-bind:selected-filters="[]"
      @updateKeywordSearch="this.updateKeywordSearch"
    />
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
          <tr class="hidden md:table-row">
            <th
              scope="col"
              class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
            >
              Nom
            </th>
            <th
              scope="col"
              class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
            >
              Statut
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white text-gray-900 text-xs font-medium">
          <tr
            v-for="store in this.stores"
            v-bind:key="store.id"
            v-bind:class="['cursor-pointer hover:bg-gray-100']"
            @click="this.redirectToStoreDetailsPage(store.id)"
          >
            <td
              v-bind:class="[
                'whitespace-nowrap py-4 pl-4 pr-2 sm:pl-4',
                isInformationToBeCompleted(store) ? 'bg-secondary' : ''
              ]"
              :colspan="storeHasName(store) ? 1 : 2"
            >
              {{ store.salesChannel }}

              <div v-if='!storeHasName(store) && isInformationToBeCompleted(store)'>
                <p class="font-bold text-md">
                  Informations à compléter
                  <span class="px-2" aria-hidden="true">&rarr;</span>
                </p>
              </div>
            </td>
            <td
              v-if="storeHasName(store)"
              :class="['py-4 pl-4 pr-2 sm:pl-4', isInformationToBeCompleted(store) ? 'bg-secondary' : '']"
            >
              <span v-if='isInformationToBeCompleted(store)' class="font-bold text-md">
                Informations à compléter
              </span>
              <span v-else-if='isPendingVerification(store)' class="font-bold text-md">
                En attente de vérification
              </span>
              <span v-else>
                {{ $t(`store.status.${store.status}`) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      v-bind:currentPage="currentPage"
      v-bind:pages="pages"
      v-bind:startIdx="startIdx"
      v-bind:endIdx="endIdx"
      v-bind:total="this.totalNumberOfStores"
      v-on:changePage="handleChangePage"
      v-if="pages.length > 1"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import StoreService from "@/api/services/store";
import Pagination from "@/components/utils/Pagination.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import GenericButton from "@/components/utils/GenericButton.vue";

const PAGE_SIZE = 25;

export default {
  components: {
    SearchBar,
    Pagination,
    GenericButton,
  },
  async mounted() {
    await this.retrieveStores(this.currentPage, this.keywords);
  },
  data() {
    return {
      stores: [],
      currentPage: 1,
      keywords: null,
      totalNumberOfStores: 1,
    };
  },
  computed: {
    ...mapState("store", ["isLoading"]),
    ...mapGetters("auth", ["currentUserHasPermission"]),
    startIdx: function () {
      return (this.currentPage - 1) * PAGE_SIZE;
    },
    endIdx: function () {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfStores);
    },
    pages: function () {
      if (this.isLoading || this.totalNumberOfStores < PAGE_SIZE) {
        return [];
      }
      return [
        ...Array(Math.ceil(this.totalNumberOfStores / PAGE_SIZE)).keys(),
      ].map((e) => e + 1);
    },
  },
  methods: {
    ...mapActions("notifications", ["notify"]),
    async retrieveStores(page, salesChannel) {
      const response = await StoreService.getStores({ page: page, sales_channel : salesChannel});
      this.stores = response.data.stores;
      this.totalNumberOfStores = response.data.totalNumberOfStores;
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.retrieveStores(page, this.keywords);
    },
    updateKeywordSearch(keywords) {
      this.keywords = keywords;
      this.handleChangePage(1);
    },
    async createStore() {
      try {
        await StoreService.createStore();
        this.notify({
          category: "simple",
          type: "success",
          title: "Nouveau point de vente créé",
          text: "Vous pouvez maintenant compléter les informations de votre point de vente",
        });
      } catch (error) {
        this.notify({
            category: "simple",
            type: "error",
            title: "Une erreur s'est produite",
            text: error.response?.data?.error || error.message,
        });
      }
      await this.retrieveStores(1, null);
    },
    isInformationToBeCompleted(store) {
      return store.setupStatus === "information_to_be_completed";
    },
    isPendingVerification(store) {
      return store.setupStatus === "pending_verification";
    },
    redirectToStoreDetailsPage(storeId) {
      this.$router.push(`/admin/stores/${storeId}`);
    },
    isStoreSetup(store) {
      return store.status === "setup";
    },
    storeHasName(store) {
      return store.salesChannel != null && store.salesChannel !== '';
    }
  },
};
</script>

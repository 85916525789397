import { isPresent } from "@/utils/validation";

export function formatPrice(locale, price, currencyCode) {
  const defaultCurrencyCode = "EUR";
  let options = {
    style: "currency",
    currency: isPresent(currencyCode) ? currencyCode : defaultCurrencyCode,
    maximumFractionDigits: price % 1 === 0 ? 0 : 2,
  };
  return new Intl.NumberFormat(locale, options).format(price);
}

export function formatFrenchPrice(price) {
  return formatPrice("fr-FR", price, "EUR");
}
